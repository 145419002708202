body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @import url('https://fonts.googleapis.com/css?family=Great+Vibes'); */

body {
  background-color: #EDD7BF;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-bottom: 1rem;
}

.wrapper {
  max-width: 1000px;
  width: 100%;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}

.App-header {
  margin-top: 20px;
  margin-bottom: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  height: 175px;
  width: auto;
  margin-right: 15px;
}

.header-title {
  font-size: 2.25rem;
  font-weight: 300;
}

.header-border {
  background-color: #DDBE9B;
  height: 2px;
  max-width: 320px;
  width: 100%;
  position: absolute;
  top: 175px;
  margin-left: -10px;
}

.nav {
  background-color: #B67231;
  width: 100%;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.nav-wrapper {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  display: -webkit-flex;
  display: flex;
}

.nav-wrapper a {
  text-decoration: none;
  /* padding-left: 15px;
  padding-right: 15px; */
  padding-bottom: 12px;
  padding-top: 12px;
  color: #000;
  font-weight: 300;
  background-color: #fff;
  margin: 5px;
  width: 100%;
  font-size: .9rem;
  max-width: 110px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.nav-wrapper a:hover {
  background-color: #CFA783;
  color: #fff;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.nav-wrapper a:first-child {
  color: #fff;
  background-color: inherit;
}

.content {
  margin-top: 2.25rem;
  border: 5px solid #FFF;
}

.title {
  text-align: center;
  background: #CC9453;
  padding: 1.25rem;
  font-size: 1.75rem;
}

.white {
  color: #FFFFFF;
}

.products {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 1.25rem;
  background-color: #F9F4DE;
}

.product {
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
}

.imageWrapper {
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
}

.productTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #2F1E19;
  margin-bottom: 1rem;
}

.properties {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.product-section {
  margin-bottom: 1rem;
}

.mobile-menu {
  display: none;
}

.mobile-menu-icon {
  display: none;
  color: #FFFFFF;
}

.contact-person {
  margin-bottom: 1rem;
}

#contact {
  text-align: center;
}

@media screen and (max-width: 550px) {
  .header-border {
    display: none;
  }
  .App-header {
    border-bottom: 2px solid #DDBE9B;
    padding-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .logo {
    height: 75px;
  }
  .header-title {
    font-size: 1.75rem;
  }
  .nav {
    display: none;
  }
  .content {
    margin-top: 1rem;
  }
  .mobile-menu {
    display: block;
  }
  .header-title {
    text-align: center;
  }
  .mobile-menu-icon {
    margin-left: 1rem;
    width: 50px;
    height: 50px;
    background-color: #B57231;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    min-width: 48px;
    min-height: 48px;
    color: #FFFFFF;
    font-size: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
  }
  .mobile-menu {
    background: #B57231;
    position: absolute;
    top: 85px;
    margin-left: 1rem;
    width: calc(100% - 2rem);
    border: 1px solid white;
    opacity: .95;
    color: #FFFFFF;
    font-size: 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .mobile-menu a {
    padding: 1.25rem;
    border-bottom: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-decoration: none;
  }
  .mobile-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

